import React, { useState } from "react";
import { TableContainer, TableHeading } from "./Table.style";
import { Image } from "../../shared/Styles/sharedStyles";
import Player from "../Player";
import axios from "axios";
import { useQuery } from "react-query";
import Constants from "../../constants";

function Table({ isLoading, Participants }: any) {
  const [currentPaginationNumber, setCurrentPaginationNumber] = useState(0);
  const total = 24;
  const pageSize = 24 / 3;

  if (isLoading) {
    return (
      <h2
        style={{
          color: "white",
        }}
      >
        Loading...
      </h2>
    );
  }

  function capitalizeFirstLetter(string: any) {
    // if (string.toLowerCase().includes("undefined")) {
    //   string.toLowerCase().replaceAll("undefined", "");
    // }

    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function generateNickName(username: any) {
    let originalUsername = username;
    originalUsername = originalUsername.trim();
    console.log("MY USERNAME", originalUsername);
    originalUsername = originalUsername.replace(/\s\s+/g, " ");
    const wordsInUsername = originalUsername.split(" ");
    console.log("MY USERNAME", wordsInUsername);

    let nickName = "";

    if (wordsInUsername.length > 0) {
      if (wordsInUsername.length == 2) {
        nickName =
          capitalizeFirstLetter(wordsInUsername[0]) +
          " " +
          capitalizeFirstLetter(wordsInUsername[1][0]) +
          ".";
        console.log("LENGTH 2", nickName);
      } else if (wordsInUsername.length == 3) {
        nickName =
          capitalizeFirstLetter(wordsInUsername[0]) +
          " " +
          capitalizeFirstLetter(wordsInUsername[2][0]) +
          ".";
      } else {
        nickName =
          capitalizeFirstLetter(wordsInUsername[0]) +
          " " +
          capitalizeFirstLetter(
            wordsInUsername[wordsInUsername.length - 1][0]
          ) +
          ".";
      }
    }

    console.log("WORDS ARR", wordsInUsername);
    console.log("Nickname", nickName);
    // console.log(
    //   "Condition",
    //   player.points.TotalPoint && player.points.TotalPoint >= 0
    // );

    if (nickName.includes("undefined")) {
      // alert("HELLO");
      nickName = nickName.replace("undefined", "");
      console.log("NICKNAME AFTER", nickName);
    }

    const finalWordsInNickName = nickName.split(" ");
    if (finalWordsInNickName.length == 2 && finalWordsInNickName[1] == ".") {
      nickName = nickName.replace(".", "");
    }

    console.log("final words", nickName);

    return nickName;
  }

  // generateNickName("Indraan Singh Toor");

  return (
    <div>
      <TableContainer>
        <thead>
          <TableHeading>
            <th>#</th>
            <th>PLAYER</th>
            <th>SCR</th>
            <th>HCP</th>
          </TableHeading>
        </thead>
        <tbody>
          {Participants?.map((player: any) => (
            <tr key={player.userId}>
              <td
                style={{
                  padding: 0,
                  textAlign: "center",
                }}
              >
                {player.position ? player.position : "-"}
              </td>
              <td>
                <Player
                  playerName={
                    generateNickName(player.userName).length >
                    Constants.playerNameInLeaderboardCharacterLimit
                      ? generateNickName(player.userName).slice(
                          0,
                          Constants.playerNameInLeaderboardCharacterLimit
                        ) + "..."
                      : generateNickName(player.userName)
                  }
                  playerFullName={player.userName}
                  profileImgUrl={player.imageUrl}
                />
              </td>
              <td
                style={{
                  textAlign: "center",
                  padding: "18px 10px",
                }}
              >
                {player.points.TotalPoint !== "0"
                  ? player.points.TotalPoint
                  : "-"}
              </td>
              <td
                style={{
                  textAlign: "center",
                  padding: "18px 10px",
                }}
              >
                {player.handicap}
              </td>
            </tr>
          ))}
        </tbody>
      </TableContainer>
    </div>
  );
}

export default Table;
