import styled from "styled-components";

export const Container = styled.nav`
  background-color: var(--main-bg);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  // height: 50px;
  padding: 3px;
  gap: 10px;
`;

export const Left = styled.div`
  // background-color: red;
`;

export const Center = styled.div`
  // background-color: orange;
  color: white;
  font-size: 24px;
  text-align: center;
`;

export const Right = styled.div`
  // background-color: blue;
  height: 80%;
`;
