import styled from "styled-components";

export const Container = styled.div`
  // background-color: green;
  height: 100%;
  padding: 10px;
  // display: flex;
  // grid-template-columns: 1fr 1fr;

  a {
    text-decoration: none;
  }
`;

export const TableContainer = styled.table`
  border-collapse: collapse;
  border-spacing: 15px;
  color: black;
  border: 0px solid grey;
  font-size: 15px;
  // width: 100%;
  text-align: center;
  min-width: 600px;
  overflow: scroll;

  th,
  tr,
  td {
    border: 0.5px solid grey;
    padding: 10px 0;
    text-align: center;
  }

  tr,
  td {
    font-weight: 400;
  }

  .marker {
    background-color: rgb(255, 238, 44);
  }

  .teeBlack {
    background-color: black;
    color: white;
  }

  .teeGold {
    background-color: #feb925;
  }

  .teeBlue {
    background-color: #cfe6ff;
  }

  .teeRed {
    background-color: #f9c7c7;
  }
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 75px;
  width: 100%;
`;
