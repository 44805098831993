import React from "react";
import Navbar from "../../components/Navbar";
import TournamentsListings from "../../components/TournamentsListings";
import { Container } from "./SelectTournamentPage.style";

function SelectTournamentPage() {
  return (
    <React.Fragment>
      <Navbar />
      <Container>
        <TournamentsListings />
      </Container>
    </React.Fragment>
  );
}

export default SelectTournamentPage;
