export default class ApiConstants {
  static tournamentsListUrl =
    "https://prod-golf.thriwe.com/parse/classes/tournamentStatus";
  static participantsUrl =
    "https://prod-golf.thriwe.com/golf/hsbc/participants";

  // static playerScoresUrl2 =
  //   "https://prod-golf.thriwe.com/parse/classes/golfScoringGameMainLeaderboard";

  static playerScoresUrl =
    "https://prod-golf.thriwe.com/parse/classes/golfScoringGameScores";

  static authToken =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiIyMDEiLCJleHAiOjE2NjU3NDE4ODEsImlhdCI6MTY2NTEzNzA4MX0.9KrnBlisgub_BbyN-BoddngUMOntBEGbkltRCzU0rVQ";

  static parseApplicationId = "PROD_APPLICATION_ID";

  static parseMasterKey = "PROD_MASTER_KEY";
}
