import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 25px;
  padding: 25px 15px;
  background: var(--main-bg);
  grid-auto-rows: 1fr;
`;

export const TournamentContainer = styled.div`
  background-color: white;
  padding: 15px;
  border-radius: 5px;

  .buttonsContainer {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  button {
    cursor: pointer;
    border: none;
    padding: 8px 15px;
    border-radius: 5px;
    background-color: var(--main-bg);
    color: white;
    font-weight: 100;
    font-size: 14px;
  }

  .title {
    margin-bottom: 10px;
    max-width: 250px;
    word-wrap: break-word;
  }
`;
