import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Wrapper = styled.div`
  height: 50px;
  width: 50px;
  // background: white;
`;
