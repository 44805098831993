import { createGlobalStyle } from "styled-components";
import styled from "styled-components";

export const GlobalStyle: any = createGlobalStyle`
  :root {
    --main-bg: #333;
  }

  body {
    height: 100vh;
    width: 100vw;
  }
`;
