import styled from "styled-components";

export const TableContainer = styled.table`
  border-collapse: collapse;
  border-spacing: 15px;
  color: white;
  border: 0px solid white;
  font-size: 30px;
  width: 100%;

  td {
    padding: 10px 8px;
    // background-color: green;
    border: 0.5px solid white;
    padding-left: 8px;
    font-size: 42px;
    text-tranform: uppercase;
    font-weight: 700;
    // width: 100%;
  }

  th {
    border: 0.5px solid white;
  }
`;

export const TableHeading = styled.tr`
  background-color: rgb(243, 69, 69);
  text-align: center;

  th {
    padding: 20px 0;
  }
`;
