import React from "react";

function returnDummyValues() {
  return (
    <>
      <td
        colSpan={2}
        style={{
          textAlign: "center",
        }}
      >
        423
      </td>
      <td>423</td>
      <td>423</td>
      <td>423</td>
      <td>423</td>
      <td>423</td>
      <td>423</td>
      <td>423</td>
      <td>423</td>
      <td>423</td>
      <td>423</td>
    </>
  );
}

function returnDummyValues5() {
  return (
    <>
      <td>423</td>
      <td>423</td>
      <td>423</td>
      <td>423</td>
      <td>423</td>
      <td>423</td>
      <td>423</td>
      <td>423</td>
      <td>423</td>
      <td>423</td>
      <td>423</td>
    </>
  );
}

function PlayerScore({
  showOnlyScores = false,
  participant = {},
  firstHalf = true,
}: any) {
  const scores = firstHalf
    ? participant?.scores?.slice(0, 9)
    : participant?.scores?.slice(9);
  const totalGrossScore = participant?.scores?.reduce(
    (total: any, num: any) => {
      let val = Number(num.grossScore) + Number(total);
      if (isNaN(val)) {
        val = 0;
      }
      return val;
    },
    0
  );

  const totalNetScore = participant?.scores?.reduce((total: any, num: any) => {
    let val = Number(num.netScore) + Number(total);
    if (isNaN(val)) {
      val = 0;
    }
    return val;
  }, 0);

  const totalPointScore = participant?.scores?.reduce(
    (total: any, num: any) => {
      let val = Number(num.points) + Number(total);
      if (isNaN(val)) {
        val = 0;
      }
      return val;
    },
    0
  );

  // console.log(
  //   "PARTICIPANT CLOSED?",
  //   participant?.participant?.points?.ScoreSubmitted
  // );

  if (firstHalf) {
    console.log("player scores", scores);
  }

  return (
    <>
      {showOnlyScores ? (
        <>
          <tr>
            {scores?.length > 0 ? (
              <>
                {scores.map((score: any) => (
                  <>
                    <td>{score?.grossScore ? score.grossScore : "-"}</td>
                  </>
                ))}
                {Array(9 - scores?.length < 0 ? 0 : 9 - scores?.length)
                  .fill("-")
                  .map((_el) => (
                    <td>-</td>
                  ))}
              </>
            ) : (
              [1, 2, 3, 4, 5, 6, 7, 8, 9].map((_el) => <td>-</td>)
            )}
            <td>
              {scores.reduce((total: any, num: any) => {
                // console.log("ADDITION", num.grossScore + total);
                let val = Number(num.grossScore) + Number(total);
                if (isNaN(val)) {
                  val = 0;
                }
                return val;
              }, 0)}
            </td>
            <td>{totalGrossScore}</td>
          </tr>
          <tr>
            {scores?.length > 0 ? (
              <>
                {scores.map((score: any) => (
                  <>
                    <td>{score.netScore ? score.netScore : "-"}</td>
                  </>
                ))}
                {Array(9 - scores?.length < 0 ? 0 : 9 - scores?.length)
                  .fill("-")
                  .map((_el) => (
                    <td>-</td>
                  ))}
              </>
            ) : (
              [1, 2, 3, 4, 5, 6, 7, 8, 9].map((_el) => <td>-</td>)
            )}
            <td>
              {scores.reduce((total: any, num: any) => {
                // console.log("ADDITION", num.grossScore + total);
                let val = Number(num.netScore) + Number(total);
                if (isNaN(val)) {
                  val = 0;
                }
                return val;
              }, 0)}
            </td>
            <td>{totalNetScore}</td>
          </tr>
          <tr>
            {scores?.length > 0 ? (
              <>
                {scores.map((score: any) => (
                  <>
                    <td>
                      {" "}
                      {score.points || score.points == 0 ? score.points : "-"}
                    </td>
                  </>
                ))}
                {Array(9 - scores?.length < 0 ? 0 : 9 - scores?.length)
                  .fill("-")
                  .map((_el) => (
                    <td>-</td>
                  ))}
              </>
            ) : (
              [1, 2, 3, 4, 5, 6, 7, 8, 9].map((_el) => <td>-</td>)
            )}
            <td>
              {scores.reduce((total: any, num: any) => {
                // console.log("ADDITION", num.grossScore + total);
                let val = Number(num.points) + Number(total);
                if (isNaN(val)) {
                  val = 0;
                }
                return val;
              }, 0)}
            </td>
            <td>{totalPointScore}</td>
          </tr>
        </>
      ) : (
        <>
          <tr>
            <th>{participant?.participant?.userName}</th>
            <td colSpan={2}>{participant?.participant?.handicap}</td>
            {/* {returnDummyValues()} */}
            {scores?.length > 0 ? (
              <>
                {scores.map((score: any) => (
                  <>
                    <td>{score.grossScore ? score.grossScore : "-"}</td>
                  </>
                ))}
                {Array(9 - scores?.length < 0 ? 0 : 9 - scores?.length)
                  .fill("-")
                  .map((_el) => (
                    <td>-</td>
                  ))}
              </>
            ) : (
              [1, 2, 3, 4, 5, 6, 7, 8, 9].map((_el) => <td>-</td>)
            )}
            <td>
              {scores.reduce((total: any, num: any) => {
                // console.log("ADDITION", num.grossScore + total);
                let val = Number(num.grossScore) + Number(total);
                if (isNaN(val)) {
                  val = 0;
                }
                return val;
              }, 0)}
            </td>
          </tr>
          <tr>
            <th>
              {participant?.participant?.points?.ScoreSubmitted == true
                ? "CLOSED"
                : "OPEN"}
            </th>
            <td colSpan={2}>
              {participant?.participant?.playerLevel > 0 ? "PRO" : "AM"}
            </td>
            {scores?.length > 0 ? (
              <>
                {scores.map((score: any) => (
                  <>
                    <td>{score.netScore ? score.netScore : "-"}</td>
                  </>
                ))}
                {Array(9 - scores?.length < 0 ? 0 : 9 - scores?.length)
                  .fill("-")
                  .map((_el) => (
                    <td>-</td>
                  ))}
              </>
            ) : (
              [1, 2, 3, 4, 5, 6, 7, 8, 9].map((_el) => <td>-</td>)
            )}
            <td>
              {scores.reduce((total: any, num: any) => {
                // console.log("ADDITION", num.grossScore + total);
                let val = Number(num.netScore) + Number(total);
                if (isNaN(val)) {
                  val = 0;
                }
                return val;
              }, 0)}
            </td>
          </tr>
          <tr>
            <th></th>
            <td colSpan={2}>{participant?.participant?.group?.TeeColorName}</td>
            {/* {returnDummyValues()} */}

            {scores?.length > 0 ? (
              <>
                {scores.map((score: any) => (
                  <>
                    <td>
                      {" "}
                      {score.points || score.points == 0 ? score.points : "-"}
                    </td>
                  </>
                ))}
                {Array(9 - scores?.length < 0 ? 0 : 9 - scores?.length)
                  .fill("-")
                  .map((_el) => (
                    <td>-</td>
                  ))}
              </>
            ) : (
              [1, 2, 3, 4, 5, 6, 7, 8, 9].map((_el) => <td>-</td>)
            )}
            <td>
              {scores.reduce((total: any, num: any) => {
                let val = Number(num.points) + Number(total);
                if (isNaN(val)) {
                  val = 0;
                }
                console.log("ADDITION", val);
                return val;
              }, 0)}
            </td>
          </tr>
        </>
      )}
    </>
  );
}

export default PlayerScore;
