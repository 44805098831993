import React from "react";
import { Center, Container, Left, Right } from "./Navbar.style";
import { Image } from "../../shared/Styles/sharedStyles";

function Navbar() {
  return (
    <Container>
      <Left>
        <div style={{ width: "260px", height: "100%" }}>
          <Image
            src="https://design.thriwe.com/hsbc-board/assets/images/logo.png"
            alt="hsbc-logo"
          />
        </div>
      </Left>
      <Center>HSBC Golf Tournament </Center>
      <Right>
        <div style={{ width: "150px", height: "100%" }}>
          <Image
            src="https://design.thriwe.com/hsbc-board/assets/images/thriwe-logo.png"
            alt="thriwe-logo"
          />
        </div>
      </Right>
    </Container>
  );
}

export default Navbar;
