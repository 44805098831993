import React, { useEffect, useRef, useState } from "react";
import { Container, Wrapper } from "./Scoreboard.style";
import FirstTable from "./FirstTable";
import SecondTable from "./SecondTable";
import { useQuery } from "react-query";
import { CSVLink } from "react-csv";
import axios from "axios";
import _ from "lodash";
import ApiConstants from "../../api-constants";
import { exit } from "process";
import Constants from "../../constants";
import { useParams } from "react-router-dom";

function Scoreboard() {
  const url = ApiConstants.participantsUrl;
  const token = ApiConstants.authToken;

  const { tournamentId, golfCourseId } = useParams();

  console.log("MY PARAMS BRO --->", { tournamentId, golfCourseId });

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  let listSize = useRef(0);

  const getResult = async () => {
    let result = [];
    while (userIds.length) {
      let splicedArr = userIds.splice(0, 10);
      // console.log("spliced arr", splicedArr);
      let r = await getScores(splicedArr);
      // console.log("response", r);
      let data = r?.data?.results ?? [];
      result.push(...data);
    }
    // console.log("final response", result);
    return { data: { results: result } };
  };

  const getDetails = () => {
    return axios.get(url, {
      headers,
      params: {
        tournamentId: tournamentId,
        tenantId: 1,
      },
    });
  };

  const { isLoading, data: Participants } = useQuery(
    "participants",
    getDetails,
    {
      select: (data: any) => {
        const participantsList = data.data.Participants;
        participantsList.sort(function (first: any, second: any) {
          if (first.points.TotalPoint > second.points.TotalPoint) {
            return -1;
          } else if (first.points.TotalPoint < second.points.TotalPoint) {
            return 1;
          } else {
            return 0;
          }
        });
        listSize.current = participantsList?.length;
        return participantsList;
      },
    }
  );

  const userIds: any = [];
  Participants?.forEach((participant: any) => {
    userIds.push(String(participant.userId));
  });

  const getScores = async (arr: any) => {
    const url = ApiConstants.playerScoresUrl;
    return axios.get(url, {
      headers: {
        "X-Parse-Application-Id": ApiConstants.parseApplicationId,
        "X-Parse-Master-Key": ApiConstants.parseMasterKey,
      },
      params: {
        include: "holeId",
        limit: 200,
        where: {
          tournamentId: tournamentId,
          userId: {
            $in: arr,
          },
        },
      },
    });
  };

  const { isLoading: load, data: Scores } = useQuery<any>(
    "participants-scores",
    getResult,
    {
      enabled: !!Participants,
    }
  );

  if (isLoading || load) {
    return <h1>Loading...</h1>;
  }

  // console.log("BRO PARTCIPANTS", Participants);
  // console.log("BRO USER IDS", userIds);
  // console.log("BRO SCORES", Scores);

  const temp2: any[] = [];

  Scores?.data?.results?.forEach((scoreOfParticipant: any) => {
    Participants?.forEach((participant: any) => {
      if (scoreOfParticipant.userId == participant.userId) {
        // console.log("DETAILS", {
        //   participant,
        //   scoreOfParticipant,
        // });
        temp2.push({
          participant,
          scoreOfParticipant,
        });
      }
    });
  });

  // console.log(
  //   "TEMP LIST",
  //   Participants?.find((participant: any) => participant.userId == 296)
  // );

  console.log("TEMP 2", temp2);

  const temp3: any[] = [];

  temp2.forEach((relatedScoresOfPlayer: any) => {
    const allScores: any[] = [];
    temp2.forEach((scores) => {
      if (
        relatedScoresOfPlayer.participant.userId ==
        scores.scoreOfParticipant.userId
      ) {
        // const scores = [];
        // scores.push(relatedScoresOfPlayer.scoreOfParticipant);
        // console.log("SCORES OF USER", {
        //   participant: relatedScoresOfPlayer.participant,
        //   scores,
        // });
        // console.log("ITEM", {
        //   1: relatedScoresOfPlayer,
        //   2: scores,
        // });
        allScores.push(scores.scoreOfParticipant);
      }
    });
    // console.log("ALL SCORES", allScores);
    temp3.push({
      participant: relatedScoresOfPlayer.participant,
      scores: allScores,
    });
  });

  console.log("TEMP 3", temp3);

  temp3.forEach((participant: any) => {
    participant?.scores?.sort(function (first: any, second: any) {
      // console.log("FIRST", first);
      if (Number(first.holeId.holeNumber) > Number(second.holeId.holeNumber)) {
        return 1;
      } else if (
        Number(first.holeId.holeNumber) < Number(second.holeId.holeNumber)
      ) {
        return -1;
      } else {
        return 0;
      }
    });
  }, []);

  console.log("SORTED ACCORDING TO HOLE NUMBER", temp3);

  const withoutDuplicateValuesData = _.uniqBy(
    temp3,
    (object) => object.participant.userId
  );

  console.log("SET", withoutDuplicateValuesData);

  const allParticipantsWithAndWithoutScores: any = [
    ...withoutDuplicateValuesData,
  ];
  const finalHolesWithMissingHoles = () => {
    allParticipantsWithAndWithoutScores?.forEach((element: any) => {
      let arr: any[] = element?.scores ?? [];
      for (let i = 1; i <= 18; i++) {
        let item2 = element?.scores[i] ?? [];
        if (element.participant.userId == "341") {
          console.log("item", item2);
        }
        // arr = [...item];
        let isElementPresentInList = checkIfHoleExists(element?.scores, i);
        if (!isElementPresentInList) {
          if (element.participant.userId == "341") {
            console.log("hole ", i, " missing from hole list");
          }
          arr.splice(i - 1, 0, {
            holeId: { holeNumber: i, par: 0 },
            grossScore: 0,
            netScore: 0,
            points: 0,
          });
        }
      }
      const scoreswithoutDuplicateValuesData = _.uniqBy(
        _.reverse(arr),
        (object) => object?.holeId?.holeNumber
      );
      element.scores = _.reverse(scoreswithoutDuplicateValuesData);
      if (element.participant.userId == "341") {
        console.log("arr with filled values", arr);
      }
    });
  };

  const checkIfHoleExists = (arr: any, holeNumber: any) => {
    let exists = false;
    arr.forEach((element: any) => {
      if (element?.holeId?.holeNumber == holeNumber) exists = true;
    });

    return exists;
  };

  Participants.forEach((participant: any) => {
    const searchResult = _.find(
      withoutDuplicateValuesData,
      function (participantWithScores: any) {
        return participant.userId == participantWithScores.participant.userId;
      }
    );

    console.log("SEARCH RESULT", searchResult);
    if (!searchResult) {
      const newEntry: any = {};
      const scores: any[] = [];
      newEntry.participant = participant;
      newEntry.scores = scores;
      console.log("SEARCH RESULT WHICH WASNT FOUND", newEntry);
      allParticipantsWithAndWithoutScores.push(newEntry);
    }
  });
  finalHolesWithMissingHoles();
  console.log(
    "MY ALL PARTICIPANTS WITH AND WITHOUT SCORE",
    allParticipantsWithAndWithoutScores
  );

  const holeCount = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
  ];

  function generateHeadersForPoints(accessorKey: string) {
    const pointsHeader = holeCount.map((playerWithScore: any, index: any) => {
      const score: any = {};
      score.label = String(index + 1);
      score.key = `${accessorKey}${index + 1}`;
      return score;
    });

    console.log("POINTS HEADER", pointsHeader);
    return pointsHeader;
  }

  const grossPointsHeader: any[] = generateHeadersForPoints("grossPointHole");
  const netPointsHeader: any[] = generateHeadersForPoints("netPointHole");
  const pointsHeader: any[] = generateHeadersForPoints("pointHole");

  const headersCsv = [
    { label: "Hole", key: "title" },
    { label: "CR", key: "cr" },
    { label: "SR", key: "sr" },
    ...grossPointsHeader.slice(0, 9),
    { label: "Out", key: "grossPointTotal" },
    { label: "", key: "" },
    // { label: "", key: "" },
    // { label: "", key: "" },
    ...grossPointsHeader.slice(9),
    { label: "IN", key: "grossPointSecondTotal" },
    { label: "Total", key: "grandGrossPointTotal" },
    { label: "", key: "" },
    { label: "", key: "" },
    { label: "", key: "" },
    ...netPointsHeader.slice(0, 9),
    { label: "Out", key: "netPointTotal" },
    { label: "", key: "" },
    // { label: "", key: "" },
    // { label: "", key: "" },
    ...netPointsHeader.slice(9),
    { label: "IN", key: "netPointSecondTotal" },
    { label: "Total", key: "grandNetPointTotal" },
    { label: "", key: "" },
    { label: "", key: "" },
    { label: "", key: "" },
    ...pointsHeader.slice(0, 9),
    { label: "Out", key: "pointTotal" },
    { label: "", key: "" },
    // { label: "", key: "" },
    // { label: "", key: "" },
    ...pointsHeader.slice(9),
    { label: "IN", key: "pointSecondTotal" },
    { label: "Total", key: "grandPointTotal" },
  ];

  console.log("POINTS HEADER CSV", headersCsv);

  function getValues(participantWithScores: any) {
    console.log("SCORE PARTICIPANT", participantWithScores);
    const values: any = {
      title: participantWithScores.participant.userName,
      cr: participantWithScores.participant.handicap,
      sr: participantWithScores.participant.playerLevel > 0 ? "PRO" : "AM",
    };

    participantWithScores.scores.forEach((score: any, index: any) => {
      values[`grossPointHole${index + 1}`] = String(score.grossScore);
    });
    participantWithScores.scores.forEach((score: any, index: any) => {
      values[`netPointHole${index + 1}`] = String(score.netScore);
    });
    participantWithScores.scores.forEach((score: any, index: any) => {
      values[`pointHole${index + 1}`] = String(score.points);
    });

    const grossScoreTotalFirstHalf = participantWithScores.scores
      .slice(0, 9)
      .reduce((total: any, num: any) => {
        // console.log("ADDITION", num.grossScore + total);
        return Number(num.grossScore) + Number(total);
      }, 0);

    const grossScoreTotalSecondHalf = participantWithScores.scores
      .slice(9)
      .reduce((total: any, num: any) => {
        // console.log("ADDITION", num.grossScore + total);
        return Number(num.grossScore) + Number(total);
      }, 0);

    const grossScoreTotal = participantWithScores.scores.reduce(
      (total: any, num: any) => {
        // console.log("ADDITION", num.grossScore + total);
        return Number(num.grossScore) + Number(total);
      },
      0
    );

    const netScoreTotalFirstHalf = participantWithScores.scores
      .slice(0, 9)
      .reduce((total: any, num: any) => {
        // console.log("ADDITION", num.grossScore + total);
        return Number(num.netScore) + Number(total);
      }, 0);

    const netScoreTotalSecondHalf = participantWithScores.scores
      .slice(9)
      .reduce((total: any, num: any) => {
        // console.log("ADDITION", num.grossScore + total);
        return Number(num.netScore) + Number(total);
      }, 0);

    const netPointsTotal = participantWithScores.scores.reduce(
      (total: any, num: any) => {
        // console.log("ADDITION", num.grossScore + total);
        return Number(num.netScore) + Number(total);
      },
      0
    );

    const pointsScoreTotalFirstHalf = participantWithScores.scores
      .slice(0, 9)
      .reduce((total: any, num: any) => {
        // console.log("ADDITION", num.grossScore + total);
        return Number(num.points) + Number(total);
      }, 0);

    const pointsScoreTotalSecondHalf = participantWithScores.scores
      .slice(9)
      .reduce((total: any, num: any) => {
        // console.log("ADDITION", num.grossScore + total);
        return Number(num.points) + Number(total);
      }, 0);

    const pointsTotal = participantWithScores.scores.reduce(
      (total: any, num: any) => {
        // console.log("ADDITION", num.grossScore + total);
        return Number(num.points) + Number(total);
      },
      0
    );

    values["grossPointTotal"] = grossScoreTotalFirstHalf;
    values["grossPointSecondTotal"] = grossScoreTotalSecondHalf;
    values["grandGrossPointTotal"] = grossScoreTotal;

    values["netPointTotal"] = netScoreTotalFirstHalf;
    values["netPointSecondTotal"] = netScoreTotalSecondHalf;
    values["grandNetPointTotal"] = netPointsTotal;

    values["pointTotal"] = pointsScoreTotalFirstHalf;
    values["pointSecondTotal"] = pointsScoreTotalSecondHalf;
    values["grandPointTotal"] = pointsTotal;

    return values;
  }

  const scoresData = allParticipantsWithAndWithoutScores.map(
    (participant: any) => {
      console.log("USER SCORES", getValues(participant));
      return getValues(participant);
    }
  );

  const data = [...scoresData];

  console.log("CSV DATA", {
    headers: headersCsv,
    data: data,
  });

  // const headersCsv = [
  //   { label: "Hole", key: "title" },
  //   { label: "CR", key: "cr" },
  //   { label: "SR", key: "sr" },
  //   { label: "1", key: "grossPointHole1" },
  //   { label: "2", key: "grossPointHole2" },
  //   { label: "3", key: "grossPointHole3" },
  //   { label: "4", key: "hole4" },
  //   { label: "5", key: "hole5" },
  //   { label: "6", key: "hole6" },
  //   { label: "7", key: "hole7" },
  //   { label: "8", key: "hole8" },
  //   { label: "9", key: "hole9" },
  //   { label: "Out", key: "" },
  //   { label: "", key: "" },
  //   // { label: "", key: "" },
  //   // { label: "", key: "" },
  //   { label: "10", key: "hole10" },
  //   { label: "11", key: "hole11" },
  //   { label: "12", key: "hole12" },
  //   { label: "13", key: "hole13" },
  //   { label: "14", key: "hole14" },
  //   { label: "15", key: "hole15" },
  //   { label: "16", key: "hole16" },
  //   { label: "17", key: "hole17" },
  //   { label: "18", key: "hole18" },
  //   { label: "IN", key: "" },
  //   { label: "Total", key: "" },
  //   { label: "", key: "" },
  //   { label: "", key: "" },
  //   { label: "", key: "" },
  //   { label: "1", key: "" },
  //   { label: "2", key: "" },
  //   { label: "3", key: "" },
  //   { label: "4", key: "" },
  //   { label: "5", key: "" },
  //   { label: "6", key: "" },
  //   { label: "7", key: "" },
  //   { label: "8", key: "" },
  //   { label: "9", key: "" },
  //   { label: "Out", key: "" },
  //   { label: "", key: "" },
  //   // { label: "", key: "" },
  //   // { label: "", key: "" },
  //   { label: "10", key: "" },
  //   { label: "11", key: "" },
  //   { label: "12", key: "" },
  //   { label: "13", key: "" },
  //   { label: "14", key: "" },
  //   { label: "15", key: "" },
  //   { label: "16", key: "" },
  //   { label: "17", key: "" },
  //   { label: "18", key: "" },
  //   { label: "IN", key: "" },
  //   { label: "Total", key: "" },
  //   { label: "", key: "" },
  //   { label: "", key: "" },
  //   { label: "", key: "" },
  //   { label: "1", key: "" },
  //   { label: "2", key: "" },
  //   { label: "3", key: "" },
  //   { label: "4", key: "" },
  //   { label: "5", key: "" },
  //   { label: "6", key: "" },
  //   { label: "7", key: "" },
  //   { label: "8", key: "" },
  //   { label: "9", key: "" },
  //   { label: "Out", key: "" },
  //   { label: "", key: "" },
  //   // { label: "", key: "" },
  //   // { label: "", key: "" },
  //   { label: "10", key: "" },
  //   { label: "11", key: "" },
  //   { label: "12", key: "" },
  //   { label: "13", key: "" },
  //   { label: "14", key: "" },
  //   { label: "15", key: "" },
  //   { label: "16", key: "" },
  //   { label: "17", key: "" },
  //   { label: "18", key: "" },
  //   { label: "IN", key: "" },
  //   { label: "Total", key: "" },
  // ];

  // const data = [
  //   {
  //     tee: "Black",
  //     cr: "1",
  //     sr: "1",
  //     hole2: "23",
  //     hole1: "241",
  //     hole3: "343",
  //     hole4: "434",
  //     hole5: "534",
  //     hole6: "634",
  //     hole7: "7234",
  //     hole8: "348",
  //     hole9: "934",
  //     hole10: "3410",
  //     hole11: "1341",
  //     hole12: "1342",
  //     hole13: "1334",
  //     hole14: "1434",
  //     hole15: "1534",
  //     hole16: "126",
  //     hole17: "1347",
  //     hole18: "1834",
  //   },
  //   {
  //     tee: "Gold",
  //     cr: "1",
  //     sr: "1",
  //     hole2: "23",
  //     hole1: "241",
  //     hole3: "343",
  //     hole4: "434",
  //     hole5: "534",
  //     hole6: "634",
  //     hole7: "7234",
  //     hole8: "348",
  //     hole9: "934",
  //     hole10: "3410",
  //     hole11: "1341",
  //     hole12: "1342",
  //     hole13: "1334",
  //     hole14: "1434",
  //     hole15: "1534",
  //     hole16: "126",
  //     hole17: "1347",
  //     hole18: "1834",
  //   },
  //   {
  //     tee: "Blue",
  //     cr: "1",
  //     sr: "1",
  //     hole2: "23",
  //     hole1: "241",
  //     hole3: "343",
  //     hole4: "434",
  //     hole5: "534",
  //     hole6: "634",
  //     hole7: "7234",
  //     hole8: "348",
  //     hole9: "934",
  //     hole10: "3410",
  //     hole11: "1341",
  //     hole12: "1342",
  //     hole13: "1334",
  //     hole14: "1434",
  //     hole15: "1534",
  //     hole16: "126",
  //     hole17: "1347",
  //     hole18: "1834",
  //   },
  //   {
  //     tee: "White",
  //     cr: "1",
  //     sr: "1",
  //     hole2: "23",
  //     hole1: "241",
  //     hole3: "343",
  //     hole4: "434",
  //     hole5: "534",
  //     hole6: "634",
  //     hole7: "7234",
  //     hole8: "348",
  //     hole9: "934",
  //     hole10: "3410",
  //     hole11: "1341",
  //     hole12: "1342",
  //     hole13: "1334",
  //     hole14: "1434",
  //     hole15: "1534",
  //     hole16: "126",
  //     hole17: "1347",
  //     hole18: "1834",
  //   },
  //   {
  //     tee: "Red",
  //     cr: "1",
  //     sr: "1",
  //     hole2: "23",
  //     hole1: "241",
  //     hole3: "343",
  //     hole4: "434",
  //     hole5: "534",
  //     hole6: "634",
  //     hole7: "7234",
  //     hole8: "348",
  //     hole9: "934",
  //     hole10: "3410",
  //     hole11: "1341",
  //     hole12: "1342",
  //     hole13: "1334",
  //     hole14: "1434",
  //     hole15: "1534",
  //     hole16: "126",
  //     hole17: "1347",
  //     hole18: "1834",
  //   },
  //   {
  //     tee: "Par",
  //     cr: "",
  //     sr: "",
  //     hole2: "23",
  //     hole1: "241",
  //     hole3: "343",
  //     hole4: "434",
  //     hole5: "534",
  //     hole6: "634",
  //     hole7: "7234",
  //     hole8: "348",
  //     hole9: "934",
  //     hole10: "3410",
  //     hole11: "1341",
  //     hole12: "1342",
  //     hole13: "1334",
  //     hole14: "1434",
  //     hole15: "1534",
  //     hole16: "126",
  //     hole17: "1347",
  //     hole18: "1834",
  //   },
  //   {
  //     tee: "HCP / SI",
  //     cr: "",
  //     sr: "",
  //     hole2: "23",
  //     hole1: "241",
  //     hole3: "343",
  //     hole4: "434",
  //     hole5: "534",
  //     hole6: "634",
  //     hole7: "7234",
  //     hole8: "348",
  //     hole9: "934",
  //     hole10: "3410",
  //     hole11: "1341",
  //     hole12: "1342",
  //     hole13: "1334",
  //     hole14: "1434",
  //     hole15: "1534",
  //     hole16: "126",
  //     hole17: "1347",
  //     hole18: "1834",
  //   },
  //   {},
  //   {
  //     tee: "Divesh Gangwani",
  //     cr: "18",
  //     sr: "AM",
  //     hole2: "23",
  //     hole1: "241",
  //     hole3: "343",
  //     hole4: "434",
  //     hole5: "534",
  //     hole6: "634",
  //     hole7: "7234",
  //     hole8: "348",
  //     hole9: "934",
  //     hole10: "3410",
  //     hole11: "1341",
  //     hole12: "1342",
  //     hole13: "1334",
  //     hole14: "1434",
  //     hole15: "1534",
  //     hole16: "126",
  //     hole17: "1347",
  //     hole18: "1834",
  //   },
  //   {
  //     tee: "Danish Raza",
  //     cr: "18",
  //     sr: "AM",
  //     hole2: "23",
  //     hole1: "241",
  //     hole3: "343",
  //     hole4: "434",
  //     hole5: "534",
  //     hole6: "634",
  //     hole7: "7234",
  //     hole8: "348",
  //     hole9: "934",
  //     hole10: "3410",
  //     hole11: "1341",
  //     hole12: "1342",
  //     hole13: "1334",
  //     hole14: "1434",
  //     hole15: "1534",
  //     hole16: "126",
  //     hole17: "1347",
  //     hole18: "1834",
  //   },
  //   {
  //     tee: "Talat Jamal",
  //     cr: "18",
  //     sr: "AM",
  //     hole2: "23",
  //     hole1: "241",
  //     hole3: "343",
  //     hole4: "434",
  //     hole5: "534",
  //     hole6: "634",
  //     hole7: "7234",
  //     hole8: "348",
  //     hole9: "934",
  //     hole10: "3410",
  //     hole11: "1341",
  //     hole12: "1342",
  //     hole13: "1334",
  //     hole14: "1434",
  //     hole15: "1534",
  //     hole16: "126",
  //     hole17: "1347",
  //     hole18: "1834",
  //   },
  // ];

  return (
    <Container>
      <Wrapper>
        <FirstTable
          participants={allParticipantsWithAndWithoutScores}
          golfCourseId={golfCourseId}
        />
        <SecondTable
          participants={allParticipantsWithAndWithoutScores}
          golfCourseId={golfCourseId}
        />
      </Wrapper>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CSVLink
          data={data}
          headers={headersCsv}
          filename="tournament-scores.csv"
        >
          <button
            style={{
              padding: "20px 40px",
              marginTop: "30px",
              cursor: "pointer",
              marginBottom: "30px",
              borderRadius: "30px",
              outline: "none",
              border: "none",
              background: "#008738",
              color: "white",
            }}
          >
            Download Excel
          </button>
        </CSVLink>
      </div>
    </Container>
  );
}

export default Scoreboard;
