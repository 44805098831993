import React from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { TableContainer } from "../Scoreboard.style";
import PlayerScore from "../../PlayerScore";
import Constants from "../../../constants";

function FirstTable({ participants = [], golfCourseId }: any) {
  console.log("TABLE PARTICIPANTS", participants);
  const url =
    "https://prod-golf.thriwe.com/parse/classes/golfcourseHoleDetails/";
  const token =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiIyMDEiLCJleHAiOjE2NjU3NDE4ODEsImlhdCI6MTY2NTEzNzA4MX0.9KrnBlisgub_BbyN-BoddngUMOntBEGbkltRCzU0rVQ";

  const headers = {
    // Authorization: `Bearer ${token}`,
    "X-Parse-Application-Id": "PROD_APPLICATION_ID",
    "X-Parse-Master-Key": "PROD_MASTER_KEY",
  };

  const getTableHeadingDetails = () => {
    return axios.get(url, {
      headers,
      params: {
        include: "tees",
        where: {
          golfcourseId: golfCourseId,
        },
      },
    });
  };

  const { isLoading, data: TableHeadingDetails } = useQuery(
    "table-heading-details",
    getTableHeadingDetails,
    {
      select: (data) => {
        const TableHeadingDetails = data.data.results;
        return TableHeadingDetails;
      },
    }
  );

  if (isLoading) {
    return <h1>Loading....</h1>;
  }

  TableHeadingDetails?.sort(function (first: any, second: any) {
    if (Number(first.holeNumber) > Number(second.holeNumber)) {
      return 1;
    } else if (Number(first.holeNumber) < Number(second.holeNumber)) {
      return -1;
    } else {
      return 0;
    }
  });

  TableHeadingDetails.forEach((tableHeading: any) => {
    tableHeading.tees.sort(function (first: any, second: any) {
      if (Number(first.teeId) > Number(second.teeId)) {
        return 1;
      } else if (Number(first.teeId) < Number(second.teeId)) {
        return -1;
      } else {
        return 0;
      }
    });
  });

  console.log("TABLE HEADING DETAILS", TableHeadingDetails);

  return (
    <>
      <TableContainer
        style={
          {
            // background: "red",
          }
        }
      >
        <tr>
          <th>Hole</th>
          <th>CR</th>
          <th>SR</th>
          <th>1</th>
          <th>2</th>
          <th>3</th>
          <th>4</th>
          <th>5</th>
          <th>6</th>
          <th>7</th>
          <th>8</th>
          <th>9</th>
          <th className="marker">Out</th>
        </tr>

        {TableHeadingDetails[0]?.tees?.map((item: any, index: number) => {
          return (
            <tr
              style={{
                backgroundColor: item.teeBoxBackColorCode,
                color: item.teeBoxFontColorCode,
              }}
            >
              <th>{item.teeBoxName ?? ""}</th>
              <td>{item.courseRating}</td>
              <td>{item.slopeRating}</td>
              {TableHeadingDetails?.slice(0, 9).map((HeadingDetail: any) => (
                <td>{HeadingDetail.tees[index].noOfYards}</td>
              ))}
              <td>
                {TableHeadingDetails[index]?.tees[index].front9YardsTotal}
              </td>
            </tr>
          );
        })}

        <tr>
          <th>Par</th>
          {/* {returnDummyValues3()} */}
          <td></td>
          <td></td>
          {TableHeadingDetails?.slice(0, 9).map((HeadingDetail: any) => (
            <td>{HeadingDetail.par}</td>
          ))}
        </tr>
        <tr>
          <th>Handicap</th>
          <td></td>
          <td></td>
          {TableHeadingDetails?.slice(0, 9).map((HeadingDetail: any) => (
            <td>{HeadingDetail.handicap_strokeIndex18Hole}</td>
          ))}
        </tr>
        <tr>
          <td
            style={{
              padding: "5px 0",
            }}
          ></td>
        </tr>
        {participants.map((participant: any, index: number) => (
          <React.Fragment key={index}>
            <PlayerScore participant={participant} />
            <tr>
              <td
                style={{
                  padding: "5px 0",
                }}
              ></td>
            </tr>
          </React.Fragment>
        ))}
      </TableContainer>
    </>
  );
}

export default FirstTable;
