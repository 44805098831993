import React, { useState } from "react";
import { Image } from "../../shared/Styles/sharedStyles";
import { PlayerPropsType } from "../../Types/Player";
import { Container, Wrapper } from "./Player.style";

function Player({
  playerName,
  profileImgUrl,
  playerFullName,
}: PlayerPropsType) {
  const [isError, setIsError] = useState(false);

  function generateInitials(playerName: string) {
    let originalPlayerName = playerName;
    originalPlayerName = originalPlayerName.trim();
    originalPlayerName = originalPlayerName.replace(/\s\s+/g, " ");
    const wordsInUsername = originalPlayerName.split(" ");

    let initials = "";

    if (wordsInUsername.length > 0) {
      if (wordsInUsername.length == 2) {
        initials = wordsInUsername[0][0] + " " + wordsInUsername[1][0];
      } else if (wordsInUsername.length === 3) {
        initials = wordsInUsername[0][0] + " " + wordsInUsername[2][0];
      } else {
        initials =
          wordsInUsername[0][0] +
          " " +
          wordsInUsername[wordsInUsername.length - 1][0];
      }
    }

    if (initials.includes("undefined")) {
      initials = initials.replace("undefined", "");
    }
    // console.log("MY INITIALS", initials);

    return initials.replaceAll(" ", "").toUpperCase();
  }

  console.log("MY PLAYER NAME", playerName);

  return (
    <Container>
      <Wrapper>
        {isError ? (
          <div
            style={{
              // background: "white",
              background: "rgb(243, 69, 69)",
              color: "white",
              display: "flex",
              justifyContent: "center",
              borderRadius: "50%",
              height: "100%",
              width: "100%",
            }}
          >
            <div
              style={{
                fontSize: "1.5rem",
                // background: "red",
                display: "flex",
                alignItems: "center",
                fontWeight: "500",
                letterSpacing: 2,
              }}
            >
              {generateInitials(playerFullName)}
            </div>
          </div>
        ) : (
          <Image
            src={profileImgUrl}
            alt=""
            style={{
              objectFit: "cover",
              borderRadius: "50%",
            }}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              // currentTarget.src =
              //   "https://design.thriwe.com/hsbc-board/assets/images/1.jpeg";
              setIsError(true);
            }}
            loading="lazy"
          />
        )}
      </Wrapper>
      <span>{playerName}</span>
    </Container>
  );
}

export default Player;
