import React from "react";
import "./App.css";
import { GlobalStyle } from "./App.style";
import Leaderboard from "./components/Leaderboard";
import Navbar from "./components/Navbar";
import axios from "axios";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Routes, Route, Navigate } from "react-router-dom";
import LeaderboardPage from "./pages/LeaderboardPage";
import ScorePage from "./pages/ScorePage";
import SelectTournamentPage from "./pages/SelectTournamentPage";

function App() {
  const queryClient = new QueryClient();

  // const url = "https://stag-golf.thriwe.com/golf/hsbc/participants";
  // const token =
  //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiIyMDEiLCJleHAiOjE2NjU3NDE4ODEsImlhdCI6MTY2NTEzNzA4MX0.9KrnBlisgub_BbyN-BoddngUMOntBEGbkltRCzU0rVQ";

  // const headers = {
  //   Authorization: `Bearer ${token}`,
  // };

  // const getDetails = () => {
  //   axios
  //     .get(url, {
  //       headers,
  //       params: {
  //         tournamentId: 1,
  //         tenantId: 1,
  //       },
  //     })
  //     .then((response) => console.log(response));
  // };

  // getDetails();

  return (
    <QueryClientProvider client={queryClient}>
      <div
        style={{
          height: "100vh",
          width: "100vw",
        }}
      >
        <GlobalStyle />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
          }}
        >
          <Routes>
            <Route path="/" element={<SelectTournamentPage />} />
            <Route
              path="/leaderboard/:tournamentId/:golfCourseId"
              element={<LeaderboardPage />}
            />
            <Route
              path="/score/:tournamentId/:golfCourseId"
              element={<ScorePage />}
            />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </div>
      </div>
      {/* <ReactQueryDevtools initialIsOpen={false} position={"bottom-right"} /> */}
    </QueryClientProvider>
  );
}

export default App;
