import React from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { TableContainer } from "../Scoreboard.style";
import PlayerScore from "../../PlayerScore";
import Constants from "../../../constants";

function SecondTable({ participants, golfCourseId }: any) {
  const url =
    "https://prod-golf.thriwe.com/parse/classes/golfcourseHoleDetails/";
  const token =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiIyMDEiLCJleHAiOjE2NjU3NDE4ODEsImlhdCI6MTY2NTEzNzA4MX0.9KrnBlisgub_BbyN-BoddngUMOntBEGbkltRCzU0rVQ";

  const headers = {
    // Authorization: `Bearer ${token}`,
    "X-Parse-Application-Id": "PROD_APPLICATION_ID",
    "X-Parse-Master-Key": "PROD_MASTER_KEY",
  };

  const getTableHeadingDetails = () => {
    return axios.get(url, {
      headers,
      params: {
        include: "tees",
        where: {
          golfcourseId: golfCourseId,
        },
      },
    });
  };

  const { isLoading, data: TableHeadingDetails } = useQuery(
    "table-heading-details",
    getTableHeadingDetails,
    {
      select: (data) => {
        const TableHeadingDetails = data.data.results;
        return TableHeadingDetails;
      },
    }
  );

  if (isLoading) {
    return <h1>Loading....</h1>;
  }

  TableHeadingDetails?.sort(function (first: any, second: any) {
    if (Number(first.holeNumber) > Number(second.holeNumber)) {
      return 1;
    } else if (Number(first.holeNumber) < Number(second.holeNumber)) {
      return -1;
    } else {
      return 0;
    }
  });

  TableHeadingDetails.forEach((tableHeading: any) => {
    tableHeading.tees.sort(function (first: any, second: any) {
      if (Number(first.teeId) > Number(second.teeId)) {
        return 1;
      } else if (Number(first.teeId) < Number(second.teeId)) {
        return -1;
      } else {
        return 0;
      }
    });
  });

  return (
    <>
      <TableContainer
        style={
          {
            // background: "red",
          }
        }
      >
        <tr>
          <th>10</th>
          <th>11</th>
          <th>12</th>
          <th>13</th>
          <th>14</th>
          <th>15</th>
          <th>16</th>
          <th>17</th>
          <th>18</th>
          <th className="marker">In</th>
          <th>Total</th>
        </tr>

        {TableHeadingDetails[9]?.tees?.map((item: any, index: number) => {
          return (
            <tr
              style={{
                backgroundColor: item.teeBoxBackColorCode,
                color: item.teeBoxFontColorCode,
              }}
            >
              {TableHeadingDetails?.slice(9).map((HeadingDetail: any) => (
                <td>{HeadingDetail.tees[index].noOfYards}</td>
              ))}
              <td>{TableHeadingDetails[index]?.tees[index].back9YardsTotal}</td>
              <td>{TableHeadingDetails[index]?.tees[index].total18Yards}</td>
            </tr>
          );
        })}

        <tr>
          {TableHeadingDetails?.slice(9).map((HeadingDetail: any) => (
            <td>{HeadingDetail.par}</td>
          ))}
          <td></td>
          <td></td>
        </tr>
        <tr>
          {TableHeadingDetails?.slice(9).map((HeadingDetail: any) => (
            <td>{HeadingDetail.handicap_strokeIndex18Hole}</td>
          ))}
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td
            style={{
              padding: "5px 0",
            }}
          ></td>
        </tr>
        {participants.map((participant: any, index: number) => (
          <React.Fragment key={index}>
            <PlayerScore
              participant={participant}
              showOnlyScores={true}
              firstHalf={false}
            />
            <tr>
              <td
                style={{
                  padding: "5px 0",
                }}
              ></td>
            </tr>
          </React.Fragment>
        ))}
      </TableContainer>
    </>
  );
}

export default SecondTable;
