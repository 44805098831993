import axios from "axios";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import ApiConstants from "../../api-constants";
import { Container, TournamentContainer } from "./TournamentsListings.style";

function TournamentsListings() {
  const navigate = useNavigate();

  const { isLoading, data: tournaments } = useQuery(
    "tournaments",
    getTournaments,
    {
      select: (data: any) => {
        const tournamentsList = data.data.results;
        return tournamentsList;
      },
    }
  );
  console.log("MY DATA BRO", tournaments);

  if (isLoading) {
    return (
      <h2
        style={{
          padding: "10px",
          color: "white",
        }}
      >
        Loading...
      </h2>
    );
  }

  return (
    <Container>
      {tournaments?.map((tournament: any) => (
        <TournamentContainer>
          <div className="title">{tournament?.tournamentName}</div>
          <small>
            <div>Tournament Id - {tournament?.tournamentId}</div>
            <div>Golf Course Id - {tournament?.golfcourseId}</div>
          </small>
          <div
            style={{
              color: tournament?.isActive ? "green" : "rgb(243, 69, 69)",
              marginTop: "15px",
              border: `1.3px dashed ${
                tournament?.isActive ? "green" : "rgb(243, 69, 69)"
              }`,
              padding: "3px 0",
              maxWidth: "90px",
              textAlign: "center",
            }}
          >
            {tournament?.isActive ? "Active" : "Not Active"}
          </div>
          <div className="buttonsContainer">
            <button
              onClick={() => {
                navigate(
                  `/leaderboard/${tournament?.tournamentId}/${tournament?.golfcourseId}`
                );
              }}
            >
              View Leaderboard
            </button>
            <button
              onClick={() => {
                navigate(
                  `/score/${tournament?.tournamentId}/${tournament?.golfcourseId}`
                );
              }}
            >
              View Scoreboard
            </button>
          </div>
        </TournamentContainer>
      ))}
    </Container>
  );
}

export default TournamentsListings;

function getTournaments() {
  const url = ApiConstants.tournamentsListUrl;
  return axios.get(url, {
    headers: {
      "X-Parse-Application-Id": "DEV_APPLICATION_ID",
      "X-Parse-Master-Key": "DEV_MASTER_KEY",
    },
  });
}
