import React from "react";
import Leaderboard from "../../components/Leaderboard";
import Navbar from "../../components/Navbar";

function LeaderboardPage() {
  return (
    <React.Fragment>
      <Navbar />
      <Leaderboard />
    </React.Fragment>
  );
}

export default LeaderboardPage;
