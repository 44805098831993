import React from "react";
import Scoreboard from "../../components/Scoreboard";

function ScorePage() {
  return (
    <div
      style={{
        height: "100%",
      }}
    >
      <Scoreboard />
    </div>
  );
}

export default ScorePage;
