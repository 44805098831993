import styled from "styled-components";

export const Container = styled.div`
  background-color: var(--main-bg);
  // background-color: green;
  flex-grow: 1;
  padding: 20px;
  // overflow: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  // grid-template-columns: repeat(auto-fill, 28rem);
  // grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  // justify-content: center;
  // overflow: scroll;
  row-gap: 40px;
  column-gap: 20px;
`;
