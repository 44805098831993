import React, { useEffect, useRef, useState } from "react";
import Table from "../Table";
import { Container } from "./Leaderboard.style";
import { useQuery } from "react-query";
import axios from "axios";
import Constants from "../../constants";
import ApiConstants from "../../api-constants";
import { useParams } from "react-router-dom";

function Leaderboard() {
  const refreshTime = Constants.refreshTime;
  const currentPaginationNumber = useRef(0);
  const listSize = useRef(0);
  const enteriesPerTable = Constants.enteriesPerTable;
  const total = enteriesPerTable * 3;
  const tables = Constants.tables;
  const showItems = Math.floor(total / tables);
  let { tournamentId, golfCourseId } = useParams();

  // const url = "https://stag-golf.thriwe.com/golf/hsbc/participants";
  const url = ApiConstants.participantsUrl;
  const token = ApiConstants.authToken;

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const getDetails = () => {
    return axios.get(url, {
      headers,
      params: {
        tournamentId: tournamentId,
        tenantId: 1,
      },
    });
  };

  const {
    isLoading,
    data: Participants,
    refetch,
  } = useQuery("participants", getDetails, {
    enabled: false,
    staleTime: 30000,
    refetchInterval: refreshTime,
    refetchIntervalInBackground: true,
    select: (data) => {
      const participantsList = data.data.Participants;
      participantsList?.sort(function (first: any, second: any) {
        if (first?.points?.TotalPoint > second?.points?.TotalPoint) {
          return -1;
        } else if (first?.points?.TotalPoint < second?.points?.TotalPoint) {
          return 1;
        } else {
          return 0;
        }
      });
      listSize.current = participantsList?.length;
      return participantsList?.slice(
        currentPaginationNumber.current * total,
        total * (currentPaginationNumber.current + 1)
      );
      // return participantsList;
    },
  });

  useEffect(() => {
    refetch();
  }, []);

  // const ref = useRef();

  useEffect(() => {
    const timer = setInterval(() => {
      if (
        currentPaginationNumber.current === Math.floor(listSize.current / total)
      ) {
        // setCurrentPaginationNumber(0);
        currentPaginationNumber.current = 0;
      } else {
        // setCurrentPaginationNumber((prev) => prev + 1);
        currentPaginationNumber.current = currentPaginationNumber.current + 1;
      }
      console.log("MY INFO", {
        currentPaginationNumber: currentPaginationNumber.current,
        listSize,
        finalSize: Math.floor(listSize.current / total),
      });
      refetch();
    }, refreshTime);
    return () => {
      clearInterval(timer);
    };
  }, []);

  // console.log("DATA", Participants);
  // console.log("BRO LIST SIze", listSize);

  console.log("MY PARAMS", { tournamentId, golfCourseId });

  return (
    <Container>
      <Table
        isLoading={isLoading}
        Participants={Participants?.slice(0, showItems * 1)}
      />
      <Table
        isLoading={isLoading}
        Participants={Participants?.slice(showItems * 1, showItems * 2)}
      />
      <Table
        isLoading={isLoading}
        Participants={Participants?.slice(showItems * 2)}
      />
    </Container>
  );
}

export default Leaderboard;
